import * as React from 'react';
import { Text, View } from 'react-native';

const AdMobRewarded = () => (
  <View>
    <Text>AdMobRewarded component not supported on the web</Text>
  </View>
);

export default AdMobRewarded;
